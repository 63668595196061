@import "app/assets/css/variables.scss";

.reject-shipment-modal {
  .gooten-modal-content {
    width: 100%;
    max-width: 550px;
  }

  .gooten-modal-body {
    padding-bottom: 0 !important;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $spacing-04;
  }
}