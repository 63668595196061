@import "app/assets/css/variables.scss";

.reports-view {
  padding: $spacing-05;

  .reports-container {
    display: flex;
    flex-direction: column;
    gap: $spacing-05;

    .reports-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 32px;
      font-weight: 600;
      padding-bottom: $spacing-05;
      height: 66px;
    }

    .search-bar-and-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .search-bar {
        width: 100%;
      }
    }

    .table-body {
      .reports-row {
        .reports-status-chip {
          .status-chip {
            margin: 0 auto;
          }
        }
      }
    }

    .reports-per-page-header {
      font-size: 20px;
      font-weight: 700;
    }

    .error-message {
      text-align: center;
      padding: 30px !important;

      svg {
        margin: -6px 3px 0 0;
      }
    }
  }
}