@import "app/assets/css/variables.scss";

.merchant-settings-view {
  display: flex;
  flex-direction: column;
  gap: $spacing-06;

  .card-body {
    height: 100px;
  }

  .settings-holder {

    .address-section-holder {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $spacing-05;
      margin-top: 10px;

      .save-address-button {
        margin-top: 20px;
      }
    }

    .settings-item-bool {
      display: flex;
      flex-direction: row;
      padding: 7px 0;

      .settings-item-title {
        padding-left: 10px;
      }
    }

    .information-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 12px;
      border-bottom: 1px solid #E8E8E8;
      line-height: 26px;
      font-size: 20px;
      font-weight: 700;
      margin-top: $spacing-03;
      margin-bottom: $spacing-05;

      &.view-mode {
        margin-top: $spacing-06;
      }
    }

    .settings-item-string {
      display: flex;
      flex-direction: row;
      padding: 7px 0;
      align-items: baseline;
      justify-content: space-between;

      .settings-item-dropdown {
        width: 35%;
      }

      .settings-item-title {
        padding-left: 10px;
      }
    }

    .settings-item-input {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: space-between;

      .settings-item-input-child {
        width: 30%;
      }
    }

    .settings-item-integer {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: space-between;

      .settings-item-integer-child {}
    }
  }
}