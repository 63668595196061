@import "app/assets/css/variables.scss";

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes glowHighlight {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: rgba(255, 255, 150, 0.4);
    box-shadow: 0 0 10px rgba(255, 255, 0, 0.4);
  }
  100% {
    background-color: transparent;
  }
}

.report-card {

  .report-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: $spacing-03;
    
    
    .left-side {
      display: flex;
      flex-direction: column;
      gap: $spacing-03;

      .report-title {
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .report-metadata {
        display: flex;
        flex-direction: column;
        gap: $spacing-02;

        .metadata-row {
          display: flex;
          align-items: center;
          gap: $spacing-03;
          font-size: 16px;
          font-weight: 400;

          .metadata-label {
            min-width: 100px;
            color:#595959;
          }

          .metadata-value {
            color: #2E2E2E;
          }
        }
      }
    }

    .right-side {
      display: flex;
      flex-direction: row;
      gap: $spacing-03;
      align-items: center;

      .button-icon {
        transition: transform 0.2s ease-in-out;
        
        &.collapsed {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .table-body {
    .reports-row {
      .report-status-chip {
        .status-chip {
          margin: 0 auto;
        }
        .status-icon-left {
          svg {
            transform-origin: center;
            animation: spin 1s linear infinite;
          }
        }
      }

      .report-actions {
        text-align: center;

        &.spin {
          svg {
            transform-origin: center;
            animation: spin 1s linear infinite;
          }
        }
      }
    }

    .history-loading {
      >div {
        display: flex;
        flex-direction: row;
        gap: $spacing-03;
        align-items: center;
        justify-content: center;
        padding: $spacing-03;
      }

      .loading-animation {
        text-align: center;

        .spinner-border {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .highlight-row {
    animation: glowHighlight 1.5s ease-in-out;
  }
}
