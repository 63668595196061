@import "app/assets/css/variables.scss";

.role-view {
    padding: $spacing-05;

    .roles-view-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 32px;
        font-weight: 600;
        padding-bottom: $spacing-05;

        .role-name {
            display: flex;
            flex-direction: row;
            gap: $spacing-04;
            font-size: 32px;
            font-weight: 600;
        }

    }

    .roles-list {
        .search-bar {
            margin-bottom: $spacing-04;
        }

        .table-header {
            .user-id-column {
                width: 225px;
            }
        }

        .table-body {
            .role-row {

                .role-actions-menu {
                    text-align: center;
                    padding: 50px;
                }
            }
        }

    }

    .no-matching-results {
        text-align: center;
        width: 100%;
        padding: 0 40px;

        svg {
            font-size: 18px;
            margin: -6px 3px 0 0;
        }
    }

    .role-load-failed {
        width: 100%;
        text-align: center;
        padding: 50px 0;

        svg {
            font-size: 18px;
            margin: -6px 3px 0 0;
        }
    }
}