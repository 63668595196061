@import "app/assets/css/variables.scss";

.gooten-table-header {
  border-bottom: 2px solid #B5B5B5;

  th {
    color: #2E2E2E;
    border: none;
    padding: $spacing-04;
    cursor: default;

    &.orderable {
      cursor: pointer;

      .column-header {
        cursor: pointer;
      }
    }

    &:first-child {
      padding-left: $spacing-07 !important;
    }

    &:last-child {
      padding-right: $spacing-07 !important;
    }

    .column-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      &.left {
        justify-content: left;
      }

      &.center {
        justify-content: center;
      }

      &.right {
        justify-content: flex-end;
      }

      .header-icons {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-left: 4px;
      }

      .filter-icon {
        display: flex;
        align-items: center;
        opacity: 0.6;
        transition: opacity 0.2s ease;
        z-index: 1;
        cursor: pointer;

        svg {
          width: 18px;
          height: 18px;
          transform: translateY(1px);

          &.active {
            color: #1969E0;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .order-status-header {
    text-align: center;
  }
}